<template>
  <div class="container-fluid">
    <div class="row mt-3">
      <h1 class="col-sm-12 text-right">Solicitud: {{ solicitud ? solicitud.solicitud_id : '--' }}</h1>
    </div>

    <div v-if="['sin_procesar','procesando','error_procesado'].indexOf(solicitud.estatus) == -1" class="contendor">
      <div class="body">
        <template v-if="!solicitud.id">
          <p>Cargando solicitud...</p>
        </template>

        <template v-else>
          <ul class="nav nav-tabs justify-content-center mb-3">
            <li class="nav-item">
              <a :class="menu_activo('resumen')" href="#" @click.prevent="menu_display='resumen'">Resumen</a>
            </li>
            <li class="nav-item">
              <a :class="menu_activo('garantia')" href="#" @click.prevent="menu_display='garantia'">Garantía</a>
            </li>
            <li class="nav-item">
              <a :class="menu_activo('comportamiento')" href="#" @click.prevent="menu_display='comportamiento'">Comportamiento</a>
            </li>
            <li class="nav-item">
              <a :class="menu_activo('capacidad_pago')" href="#" @click.prevent="menu_display='capacidad_pago'">Capacidad de pago</a>
            </li>
            <li class="nav-item">
              <a :class="menu_activo('decision')" href="#" @click.prevent="menu_display='decision'">Decisión modelo</a>
            </li>
          </ul>

          <Info v-if="menu_display=='resumen'" :solicitud="solicitud" />
          <Garantia v-if="menu_display=='garantia'" :solicitud="solicitud" />
          <Comportamiento v-if="menu_display=='comportamiento'" :solicitud="solicitud" />
          <CapacidadPago v-if="menu_display=='capacidad_pago'" :solicitud="solicitud" />
          <DecisionModelos v-if="menu_display=='decision'" :solicitud="solicitud" />
        </template>
      </div>
      <div :class="'votes'+(tipo_visualizacion == 'modal' ? ' votes_modal' : '')">
        <div v-if="!a_votado && tipo_visualizacion == 'votos'" class="vote-accept"><i @click="abrir_voto('aceptado')" title="Votar la solicitud como Aceptada" class="fa-regular fa-circle-check"></i></div>
        <div v-if="!a_votado && tipo_visualizacion == 'votos'" class="vote-decline"><i @click="abrir_voto('rechazado')" title="Votar la solicitud como Rechazada" class="fa-regular fa-circle-xmark"></i></div>
        <div v-if="solicitud.estatus == 'resolucion' || (!a_votado && tipo_visualizacion == 'votos')" class="comments"><i @click="show_observaciones=true" title="Ver comentarios..." class="fa-regular fa-comments"></i></div>
        <div v-if="solicitud.estatus == 'resolucion' && $auth.can('analyticpoint','usuario_admin')" class="minuta"><i @click.prevent="descargar_minuta" class="fa-solid fa-file-arrow-down" title="Descargar minuta"></i></div>
        <div v-if="solicitud.estatus == 'resolucion' && $auth.can('analyticpoint','usuario_admin')" class="minuta"><i @click.prevent="solicitar_minuta" class="fa-solid fa-inbox" title="Solicitar minuta"></i></div>
      </div>
    </div>

    <div v-else class="container">
      <div class="row">
        <div class="col-sm-12">La solicitud a presentado los siguientes eventos:</div>
      </div>

      <div v-if="solicitud" class="row">
        <div class="col-sm-12">
          <DataGrid :configuracion="datagrid_config" :data="solicitud.bitacora"></DataGrid>
        </div>
      </div>
      <div v-else>Cargando datos...</div>
    </div>

    <Observaciones v-if="show_observaciones" :solicitud="solicitud" @close="cerrar_observaciones" />
    <Voto v-if="show_voto" :solicitud="solicitud" :resolucion="voto_resolucion" @close="cerra_voto" @update="finalizar_voto" />
  </div>
</template>

<script>
  import api from '@/apps/analyticpoint/api/comite/solicitudes';

  import DataGrid from '@/components/DataGridV3';

  import Info from './Info';
  import Garantia from './Garantia';
  import Comportamiento from './Comportamiento';
  import CapacidadPago from './CapacidadPago';
  import DecisionModelos from './DecisionModelos';
  import Observaciones from './Observacion.vue';
  import Voto from './Voto.vue';
  
  export default {
    props: {
      solicitud_id: {
        type: String
      },
      tipo_visualizacion: {
        type: String,
        default: 'votos'
      }
    },
    components: {
      DataGrid, Info,Garantia,Comportamiento,CapacidadPago,DecisionModelos, Observaciones,Voto
    },
    data() {
      return {
        solicitud: {
          votos:[]
        },
        menu_display: 'resumen',
        show_observaciones: false,
        show_voto: false,
        voto_resolucion: 'aprobado',
        datagrid_config: {
          name: 'errores',
          selector: false,
          cols: {
            nombre: 'Nombre',
            accion: 'Evento',
            created_at: 'Fecha'
          },
          mutators: {
            created_at(val, row, vue) {
              return vue.$moment(val).format('dddd DD MMMM YYYY HH:mm')+'hrs';
            }
          }
        }
      }
    },
    mounted() {
      this.obtener_solicitud();
    },
    methods: {
      async obtener_solicitud() {
        try {
          this.solicitud = (await api.obtener_solicitud(this.$route.params.solicitud ? this.$route.params.solicitud : this.solicitud_id)).data;
          this.$emit('solicitud', this.solicitud);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      },
      menu_activo(tab) {
        return 'nav-link'+(this.menu_display == tab ? ' active' : '');
      },
      cerrar_observaciones() {
        this.show_observaciones = false;
        // this.obtener_solicitud();
      },
      abrir_voto(resolucion) {
        this.voto_resolucion = resolucion;
        this.show_voto = true;
      },
      cerra_voto() {
        this.voto_resolucion = 'aprobado';
        this.show_voto = false;
      },
      finalizar_voto() {
        this.obtener_solicitud();
        this.cerra_voto();
      },
      async descargar_minuta() {
        let minuta = null;

        this.solicitud.documentos.forEach(documento => {
          if (documento.tipo == 'decision')
            minuta = documento;
        });

        if (!minuta) {
          this.$helper.showMessage('Error','Esta solicitud no tiene minuta guardada','error','alert');
          return
        }

        let documento = (await api.descargar_minuta(this.solicitud.id,minuta.id)).data;

        this.$helper.descargar_documento(documento, minuta.nombre_original);
      },
      async solicitar_minuta() {
        try {
        let res = (await api.generar_minuta(this.solicitud.id)).data;

        this.$helper.showMessage('Exito!','Se ha solicitado la generación de la minuta, te llegará por correo','succes','alert');
      }catch(e) {
        this.$log.error('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
      },
      tiene_minuta() {
        let minuta = null;

        this.solicitud.documentos.forEach(documento => {
          if (documento.tipo == 'decision')
            minuta = documento;
        });

        return minuta !== null;
      }
    },
    computed: {
      a_votado() {
        let encontrado = false;

        if (this.solicitud)
          this.solicitud.votos.forEach(voto => {
            if (!encontrado && voto.account_id == this.$auth.getUser().id)
              encontrado = true;
          })

        return encontrado;
      }
      ,cerrar_voto() {

      }
    }
  }
</script>

<style lang="scss" scoped>
.contendor {
  margin-bottom: 100px;
  position: relative;

  .body {
    // order: 0;
  }

  .votes {
    position: fixed;
    bottom: 20px;
    right: 20px;

    i {
      font-size: 2.5em;
    }

    div {
      background-color: #fff;
      margin-right: 10px;
      padding: 10px;
      border: solid 2px #8b8b8b;
      border-radius: 50px;
      display: inline-block;
      box-shadow: 10px 10px 20px -1px rgba(0,0,0,0.65);
      -webkit-box-shadow: 10px 10px 20px -1px rgba(0,0,0,0.65);
      -moz-box-shadow: 10px 10px 20px -1px rgba(0,0,0,0.65);

      i {
        cursor: pointer;
      }
    }

    .vote-accept {
      color: #24922d;
    }

    .vote-decline {
      color: red;
    }

    .minuta {
      padding: 10px 15px;
      border-radius: 80px !important;
    }
  }

  .votes_modal {
    bottom: 65px;
    right: 25px;
  }
}

.sin_votos {
  margin-bottom: 0px !important;
}

@media (max-width: 768px) {
  .votes {
    bottom: 5px !important;
    right: 0px !important;

    i {
      margin-top: 3px;
      font-size: 1.5em !important;
    }

    div {
      padding: 5px 9px !important;
      border-radius: 40px !important;
    }
  }
}

@media (min-width: 769px) and (max-width:1025px) {
  .votes {
    bottom: 15px !important;
    right: 5px !important;

    i {
      margin-top: 3px;
      font-size: 2.3em !important;
    }

    div {
      padding: 5px 9px !important;
      border-radius: 40px !important;
    }
  }
}
</style>